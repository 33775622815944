import React, { useState } from "react"
import { AnimatePresence, motion, Reorder } from "framer-motion"
import { v4 as uuid } from "uuid"
import { TextInput, LabeledInput } from "./Modal"
import classNames from "classnames"
import DatePicker from "./DatePicker"
// import FieldExplanationTooltip from "./FieldExplanationTooltip"

import { Disclosure } from "@headlessui/react"

type LinkGroup = {
  id: number | string
  label?: string
  name: string
  links: Link[]
  _destroy?: boolean
}

type Link = {
  id: number | string
  label?: string
  url: string
  published_at?: string
  expired_at?: string
  attachment?: string
  fileName?: string
  _destroy?: boolean
}

export default function LinksEditor({
  allowedNames = ["Menus", "Reservations"],
  linkGroups: initialLinkGroups = [],
  ownerId,
  ownerType,
}) {
  const [linkGroups, setLinkGroups] = useState<LinkGroup[]>(initialLinkGroups)
  return (
    <>
      {linkGroups.map(({ id: groupId, name = "", label, links = [], _destroy }) => {
        const isPersisted = !!`${groupId}`.match(/^\d+$/)
        return (
          <React.Fragment key={groupId}>
            {isPersisted ? (
              <>
                <input
                  type="hidden"
                  name={`${ownerType}[link_groups_attributes][${groupId}][id]`}
                  value={groupId}
                />
                {_destroy ? (
                  <input
                    type="hidden"
                    name={`${ownerType}[link_groups_attributes][${groupId}][_destroy]`}
                    value="1"
                  />
                ) : null}
              </>
            ) : null}
            <input
              type="hidden"
              name={`${ownerType}[link_groups_attributes][${groupId}][name]`}
              value={name}
            />
            <input
              type="hidden"
              name={`${ownerType}[link_groups_attributes][${groupId}][label]`}
              value={label}
            />
            {links.map(({ id, label, url, published_at, expired_at, attachment, _destroy }, i) => {
              const isPersisted = !!`${id}`.match(/^\d+$/)
              if (!isPersisted && _destroy) return null
              return (
                <React.Fragment key={id}>
                  {isPersisted ? (
                    <input
                      type="hidden"
                      name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][id]`}
                      value={id}
                    />
                  ) : null}
                  {_destroy ? (
                    <input
                      type="hidden"
                      name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][_destroy]`}
                      value="1"
                    />
                  ) : null}
                  <input
                    type="hidden"
                    name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][label]`}
                    value={label}
                  />
                  <input
                    type="hidden"
                    name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][url]`}
                    value={url}
                  />
                  <input
                    type="hidden"
                    name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][position]`}
                    value={i + 1}
                  />
                  <input
                    type="hidden"
                    name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][published_at]`}
                    value={published_at}
                  />
                  <input
                    type="hidden"
                    name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][expired_at]`}
                    value={expired_at || ""}
                  />
                  {attachment && (
                    <input
                      id={`attachment-${attachment}`}
                      type="file"
                      name={`${ownerType}[link_groups_attributes][${groupId}][links_attributes][${id}][attachment]`}
                      onChange={(e) => {
                        const file = e.target.files[0]
                        setLinkGroups((linkGroups) => {
                          const index = linkGroups.findIndex(
                            (g) => g?.links?.findIndex((l) => l.id === id) > -1,
                          )
                          const linkIndex = linkGroups[index].links.findIndex((l) => l.id === id)
                          linkGroups[index].links[linkIndex].fileName = file?.name || ""
                          return [...linkGroups]
                        })
                      }}
                      className="hidden"
                    />
                  )}
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )
      })}
      <div className="flex flex-col gap-4">
        {linkGroups
          .filter((group) => !group._destroy)
          .map((group) => (
            <LinkGroupEditor
              key={group.id}
              {...group}
              onUpdate={(g) => {
                const index = linkGroups.findIndex((g) => g.id === group.id)
                linkGroups[index] = g
                setLinkGroups(
                  [...linkGroups].filter((g) => !(g._destroy && !`${g.id}`.match(/^\d+$/))),
                )
              }}
            />
          ))}
      </div>
      <div className="flex items-center gap-2 px-1 pt-2">
        {allowedNames
          .filter((name) => !linkGroups.find((g) => g.name.toLowerCase() === name.toLowerCase()))
          .map((name) => (
            <button
              key={name}
              type="button"
              className="inline-flex items-center gap-2"
              onClick={(e) => {
                setLinkGroups([
                  ...linkGroups,
                  {
                    id: uuid(),
                    name,
                    label: name,
                    links: [
                      // { id: uuid(), label: name, url: "" }
                    ],
                  },
                ])
              }}
            >
              <span>Add {name}</span>
              <i className="fa fa-plus text-picton-blue" />
            </button>
          ))}
        <button
          type="button"
          className="inline-flex items-center gap-2"
          onClick={(e) => {
            setLinkGroups([
              ...linkGroups,
              {
                id: uuid(),
                name: "Generic",
                label: "",
                links: [
                  // { id: uuid(), label: "", url: "" }
                ],
              },
            ])
          }}
        >
          <span>Add Generic Links</span>
          <i className="fa fa-plus text-picton-blue" />
        </button>
      </div>
    </>
  )
}

const LinkGroupEditor = ({
  id,
  label = "",
  name = "",
  links = [],
  onUpdate,
}: LinkGroup & {
  onUpdate: (group: LinkGroup) => void
}) => {
  const isPersisted = !!`${id}`.match(/^\d+$/)
  const [nameLocked, setNameLocked] = useState(true)
  return (
    <div className="relative flex flex-col rounded border border-blue-500/50 p-2">
      <Disclosure defaultOpen={!isPersisted}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <span>{name}</span>
                <div className="flex items-center justify-center rounded-full bg-slate-200/30 px-3 text-sm text-slate-600">
                  <span>{links.filter((l) => !l._destroy).length.toString()}</span>
                </div>
                <i className={classNames(["fa fa-chevron-right", open && "rotate-90 transform"])} />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel static>
              <AnimatePresence>
                {open && (
                  <motion.div
                    initial={{ opacity: 0, scaleY: 0, height: 0 }}
                    animate={{ opacity: 1, scaleY: 1, height: "auto", originY: "top" }}
                    exit={{ opacity: 0, scaleY: 0, height: 0 }}
                    className="mt-2 overflow-hidden rounded-sm border border-black/10 p-2"
                  >
                    <div className="relative flex items-center gap-2 px-1 pb-2">
                      <label className="">
                        Group Type:
                        <div className="relative">
                          <TextInput
                            disabled={nameLocked}
                            value={name}
                            onChange={(el) => onUpdate({ id, name: el.target.value, label, links })}
                            placeholder="Links"
                          />
                          {nameLocked && (
                            <div className="absolute inset-0 flex items-center justify-end px-2">
                              <button
                                type="button"
                                onClick={() =>
                                  confirm(
                                    "This field is used by your website. Are you sure you want to change it?",
                                  ) && setNameLocked(false)
                                }
                              >
                                <span className="sr-only">Unlock Group Type</span>
                                <i className="fa fa-lock" />
                              </button>
                            </div>
                          )}
                        </div>
                      </label>
                      <label className="">
                        Label:
                        <TextInput
                          value={label}
                          onChange={(el) => onUpdate({ id, label: el.target.value, name, links })}
                          placeholder="Links"
                        />
                      </label>
                    </div>
                    <Reorder.Group
                      axis="y"
                      values={links}
                      onReorder={(newLinks) => onUpdate({ id, name, links: newLinks })}
                      className="space-y-2"
                    >
                      {links
                        .filter((link) => !link._destroy)
                        .map((link) => (
                          <LinkEditor
                            key={link.id}
                            link={link}
                            onUpdate={(newLink) => {
                              const index = links.findIndex((l) => l.id === link.id)
                              links[index] = newLink
                              onUpdate({
                                id,
                                name,
                                links: [...links],
                              })
                            }}
                          />
                        ))}
                    </Reorder.Group>
                    <div className="flex items-center justify-end gap-4 p-2">
                      <button
                        type="button"
                        className="inline-flex items-center gap-2"
                        onClick={() => {
                          onUpdate({
                            id,
                            name,
                            label,
                            links: [
                              ...links,
                              {
                                id: uuid(),
                                label: "",
                                url: "",
                                published_at: new Date().toISOString(),
                              },
                            ],
                          })
                        }}
                      >
                        <span>Add a new link</span>
                        <i className="fa fa-plus text-picton-blue" />
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center gap-2"
                        onClick={() => {
                          onUpdate({
                            id,
                            name,
                            label,
                            links: [
                              ...links,
                              {
                                id: uuid(),
                                label: "",
                                url: "",
                                attachment: uuid(),
                                published_at: new Date().toISOString(),
                              },
                            ],
                          })
                        }}
                      >
                        <span>Upload a file</span>
                        <i className="fa fa-plus text-picton-blue" />
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="group absolute right-3 top-2 flex items-center gap-2">
        <button
          className={classNames([
            "inline-flex items-center gap-2",
            "origin-right scale-x-0 opacity-0 transition-all",
            "group-focus-within:scale-x-100 group-focus-within:opacity-100",
            "group-hover:scale-x-100 group-hover:opacity-100",
          ])}
          onClick={(e) => {
            e.preventDefault()
            if (confirm("Are you sure you want to delete this group?")) {
              onUpdate({ id, name, links, _destroy: true })
            }
          }}
        >
          <span className="">Delete Group</span>
          <i className="fa fa-trash" />
        </button>
        <i className="fa fa-ellipsis-v group-hover:opacity-50"></i>
      </div>
    </div>
  )
}

const LinkEditor = ({ link, onUpdate }) => {
  const [scheduling, setScheduling] = useState(false)
  return (
    <Reorder.Item value={link}>
      <div className="rounded-sm border border-blue-500/70 p-2">
        <div className="flex items-center justify-between">
          <div className="flex grow items-center gap-2">
            <label className="">
              Label:
              <TextInput
                value={link.label}
                onChange={(el) => onUpdate({ ...link, label: el.target.value })}
                placeholder="Label"
              />
            </label>
            <div className="grow">
              {link.attachment ? (
                <label className="grow">
                  Attachment
                  <button
                    type="button"
                    onClick={() => {
                      document.getElementById(`attachment-${link.attachment}`)?.click()
                    }}
                    className="flex items-center gap-2 rounded border border-gray-400 p-1 px-2 hover:bg-gray-200"
                  >
                    <span>{link.fileName ? "Change" : "Select"} File</span>{" "}
                    <i className="fa fa-paperclip"></i>
                    <span>{link.fileName}</span>
                  </button>
                </label>
              ) : (
                <label className="grow">
                  URL:
                  <TextInput
                    value={link.url}
                    onChange={(el) => onUpdate({ ...link, url: el.target.value })}
                    placeholder="https://example.com"
                  />
                </label>
              )}
            </div>
            <div className="flex flex-col gap-2 self-stretch p-1">
              <button
                type="button"
                className=""
                onClick={() => {
                  const id = `${link.id}`.match(/^\d+$/) ? link.id : "new"
                  return confirm("Are you sure?") && onUpdate({ ...link, id, _destroy: true })
                }}
              >
                <i className="fa fa-trash" />
              </button>

              <button
                type="button"
                className=""
                onClick={() => setScheduling((scheduling) => !scheduling)}
              >
                <i className="fa fa-calendar" />
              </button>
            </div>
          </div>
        </div>
        <Disclosure>
          <Disclosure.Panel static>
            <AnimatePresence>
              {scheduling && (
                <motion.div
                  initial={{ opacity: 0, scaleY: 0, height: 0 }}
                  animate={{ opacity: 1, scaleY: 1, height: "auto" }}
                  exit={{ opacity: 0, scaleY: 0, height: 0 }}
                  className="mt-3 flex gap-4 overflow-hidden rounded-sm bg-slate-400/30 px-2 pb-6 pt-4 text-slate-700"
                >
                  <LabeledInput label="Published At">
                    <DatePicker
                      value={link?.published_at}
                      onChange={(date) => {
                        return onUpdate({
                          ...link,
                          published_at: date,
                        })
                      }}
                    />
                  </LabeledInput>
                  <LabeledInput label="Expired At">
                    <DatePicker
                      value={link?.expired_at}
                      onChange={(date) =>
                        onUpdate({
                          ...link,
                          expired_at: date,
                        })
                      }
                      placeholder="Never expire"
                    />
                  </LabeledInput>
                </motion.div>
              )}
            </AnimatePresence>
          </Disclosure.Panel>
        </Disclosure>
      </div>
    </Reorder.Item>
  )
}
