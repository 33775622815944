export default function rewards_points_store_multiplier() {
  document
    .querySelectorAll("[data-name=add-store-multipliers-button]")
    .forEach((rewards_points_store_multiplier: HTMLElement) => {
      rewards_points_store_multiplier.addEventListener("click", (e) => {
        let last_multiplier = 0
        Array.from(document.querySelectorAll(".store-multiplier-pair")).map(
          (multiplier: HTMLElement) => {
            last_multiplier = parseInt(multiplier.dataset.storeMultiplier)
          },
        )

        function store_multiplier_html(i: number) {
          return `
            <div class="field flex store-multiplier-pair" data-store-multiplier="${i}">
              <div class="mr-2">
                <label for="rewards_multiplier_store_ids_${i}_store_id">Store ID</label>
                <input class=" rhx-input rhx-text-input" type="text" name="rewards_multiplier[store_ids][${i}][store_id]" id="rewards_multiplier_store_ids_${i}_store_id">
              </div>
              <div>
                <label for="rewards_multiplier_store_ids_${i}_multiplier">Multiplier</label>
                <input class=" rhx-input rhx-text-input" type="text" name="rewards_multiplier[store_ids][${i}][multiplier]" id="rewards_multiplier_store_ids_${i}_multiplier">
              </div>
            </div>
          `
        }

        let additional_store_multiplier_html = ""

        for (let i = last_multiplier + 1; i < last_multiplier + 4; i++) {
          additional_store_multiplier_html += store_multiplier_html(i)
        }

        const store_multipliers_container = document.getElementById("store-multipliers")
        store_multipliers_container.insertAdjacentHTML(
          "beforeend",
          additional_store_multiplier_html,
        )
      })
    })
}
